import { useState } from "react";
import { apiAuth } from "../endpoints";

export const useLogin = () => {
    const [loading, setLoading] = useState(false);

    const handleLogin = async (userId: string) => {
        try {
            setLoading(true);
            const response = await apiAuth(userId);
            console.log('auth: ', response);
            const { data, error } = response;

            if (error) {
                throw new Error(error);
            }
            return data;
        } catch (error) {
            setLoading(false);
            throw error;
        }
    }

    return { login: handleLogin, loading };
};
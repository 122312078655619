import { SOCKET_URL } from "constants/common";
import { useEffect, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { FightEventType } from "types/match";
import { safeJsonParse } from "utils/common";

interface Params {
  onDisconnect: () => void;
}

export const useFightWS = ({ onDisconnect }: Params) => {
  const [messageHistory, setMessageHistory] = useState<MessageEvent<any>[]>([]);
  const [connect, setConnect] = useState(false);
  const handleConnect = () => setConnect(true);
  const handleDisconnect = () => {
    onDisconnect()
    setConnect(false);
  }
  const [matchStarted, setMatchStarted] = useState(false);
  const [fightStarted, setFightStarted] = useState(false);

  const handleOnError = (err: Event) => {
    // console.log(err);
    handleDisconnect();
  };

  const { sendMessage, lastMessage, readyState } = useWebSocket(
        SOCKET_URL,
        { onError: handleOnError, queryParams: { authorization: "123"}, onClose: handleDisconnect },
        connect
    );

  useEffect(() => {
    if (lastMessage !== null) {
        const parsedMessage = safeJsonParse(lastMessage.data);
        setMessageHistory((prev) => prev.concat(lastMessage));

        console.log('received message: ', parsedMessage);

        if (parsedMessage.type) {
          if (parsedMessage.type === FightEventType.Created) {
            setMatchStarted(true);
          }

          if (parsedMessage.type === FightEventType.FightStarted) {
            setFightStarted(true);
          }

          if (parsedMessage.type === FightEventType.FightFinished) {
            setMatchStarted(false);
            setFightStarted(false);
          }
        }
    }
  }, [lastMessage]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  const handleFindMatch = () => {
    sendMessage('{}')
  };

  const handleHit = (hitType: 'ROCK' | 'SCISSORS' | 'PAPER') => {
    sendMessage(JSON.stringify({
      type: 'HIT',
      hitType: hitType
  }))
  }

  return {
    connect: handleConnect,
    connectionStatus,
    connected: readyState === ReadyState.OPEN,
    findMatch: handleFindMatch,
    hit: handleHit,
    matchStarted,
    fightStarted
  }
};

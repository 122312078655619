import React, {useCallback, useState} from 'react';
// import cn from 'classnames';
import './App.css';
import {useFightWS} from 'hooks/useFightWS';
import {useLogin} from 'api/hooks/useLogin';
import {AuthDto} from 'types/auth';

function App() {
    const [userInfo, setUserInfo] = useState<AuthDto>();
    const {login, loading: loginLoading} = useLogin();
    const {
        connect,
        connectionStatus,
        // connected,
        findMatch,
        hit,
        // matchStarted,
        // fightStarted
    } = useFightWS({
        onDisconnect: () => {
            setUserInfo(undefined);
        }
    });

    const handleLogin = useCallback((userId: string) => {
        if (!userInfo?.id && !loginLoading) {
            login(userId).then((data) => {
                setUserInfo(data);
                connect();
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo?.id, loginLoading]);

    // const handleFindMatch = useCallback(() => {
    //   if (connected) {
    //     findMatch();
    //   }
    // }, [connected, findMatch]);


    return (
        <div className="App">
            <div className='user-info'>
                <div>User ID: {userInfo?.id}</div>
                <div>Score: {userInfo?.score}</div>
            </div>
            <div className="action-buttons">
                <div className="login">
                    <button
                        // className={cn('connect-button', (!!userInfo?.id || loginLoading) && 'disabled')}
                        className="connect-button"
                        onClick={() => handleLogin('1')}
                    >Login 1
                    </button>
                    <span>  </span>
                    <button
                        // className={cn('connect-button', (!!userInfo?.id || loginLoading) && 'disabled')}
                        className="connect-button"
                        onClick={() => handleLogin('2')}
                    >Login 2
                    </button>
                </div>
                <div
                    // className={cn('connect-button', connected && 'disabled')}
                    className="connect-button"
                    // onClick={!connected ? connect : undefined}
                    onClick={connect}
                >Connect WS
                </div>
                {`Connection status: ${connectionStatus}`}

                <div
                    // className={cn('connect-button', (!connected || matchStarted) && 'disabled')}
                    className="connect-button"
                    onClick={findMatch}
                >Find Match
                </div>

                <div className="hit">
                    <button
                        // className={cn('connect-button', !fightStarted && 'disabled')}
                        className="connect-button"
                        onClick={() => hit('ROCK')}
                    >ROCK
                    </button>
                    <span>  </span>
                    <button
                        // className={cn('connect-button', !fightStarted && 'disabled')}
                        className="connect-button"
                        onClick={() => hit('SCISSORS')}
                    >SCISSORS
                    </button>
                    <span>  </span>
                    <button
                        // className={cn('connect-button', !fightStarted && 'disabled')}
                        className="connect-button"
                        onClick={() => hit('PAPER')}
                    >PAPER
                    </button>
                </div>
                {/* <WebSocketDemo /> */}
            </div>
        </div>
    );
}

export default App;

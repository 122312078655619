import axios, { InternalAxiosRequestConfig, AxiosResponse } from 'axios';
import { BASE_URL } from 'constants/common';
// import { getTokenLS } from 'utils/localStorage';

export const onSuccessResponseCallback = (response: AxiosResponse) => response;

const onSuccessRequestCallback = (config: InternalAxiosRequestConfig) => {
    const newConfig = { ...config };

    if (!newConfig.headers) {
        // @ts-ignore
        newConfig.headers = {};
    }
    newConfig.headers.Authorization = `Bearer 123`;

    return newConfig;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const onErrorResponseCallback = (error: any) => {
    if (
        error.config.url !== '/api/auth/login' &&
        error.response?.status === 401 &&
        error.response?.statusText === 'Unauthorized'
    ) {
        localStorage.clear();
    }
    return Promise.reject(error);
};

export const apiClient = axios.create({
    baseURL: BASE_URL
});

apiClient.interceptors.request.use(onSuccessRequestCallback);

apiClient.interceptors.response.use(onSuccessResponseCallback, onErrorResponseCallback);

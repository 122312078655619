import { AxiosError, AxiosResponse } from 'axios';

export const withDefaultHandlers = <T = any>(request: Promise<AxiosResponse<T>>): Promise<T> =>
    request
        .then((response: AxiosResponse<T>): T => {
            return response.data;
        })
        .catch((error: Error | AxiosError) => {
            throw error;
        });

import { ResponseDto } from 'types/common';
import { apiClient } from './apiClient';
import { withDefaultHandlers } from './helpers';
import { AuthDto } from 'types/auth';

// const BASE_PATH = '/api';
// const BASE_PATH = '/api/account/balance';

export const apiAuth = (userId: string) =>
    withDefaultHandlers<ResponseDto<AuthDto>>(apiClient.post(`/auth`, undefined, { params: { userId } }));

// export const apiAuth = (userId: string) => new Promise<ResponseDto<AuthDto>>((resolve) => {
//     setTimeout(() => {
//         resolve({
//             data: {
//                 id: userId,
//                 score: 100,
//             },
//             error: null,
//         });
//     }, 1000);
// });